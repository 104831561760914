import { CONFIG_URL } from 'constants/urls'

export type EmbedConfig = {
  id: string
  display_type?: 'inline' | 'popup'
  background_color: string
  headline: string
  headline_text_color: string
  subtext: string
  subtext_text_color: string
  button_background_color: string
  button_text_color: string
  phone_number: string
  name: string
  force_view?: string
  code: string
}

export function fetchConfig(id: string): Promise<EmbedConfig> {
  const bust = Math.floor(Date.now() / 1000)
  const url = `${CONFIG_URL}/member-growth-units/v1/${id}?_bust=${bust}`
  return window.fetch(url).then((resp) => resp.json())
}
