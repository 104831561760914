import { fetchConfig } from './api/config'

import { APP_URL } from 'constants/urls'

type ErrorEvent = Event & {
  error: Error
}

const id = window.document.currentScript?.dataset.communityEmbedId
const cookieName = `community-embed-${id}`
const embedElementId = 'community-embed'
const channel = new MessageChannel()
const port1 = channel.port1
port1.onmessage = handleIframeMessage

function handleIframeMessage(event: MessageEvent) {
  switch (event.data.action) {
    case 'error':
      console.log('iframe error')
      break
    case 'submit':
      console.log('iframe submitted')
      break
    case 'close':
      console.log('iframe closed')
      break
    default:
      break
  }
}

function createIframe(config) {
  const iframe = document.createElement('iframe')
  iframe.setAttribute('allow', 'geolocation')
  iframe.setAttribute('allowtransparency', 'true')
  iframe.setAttribute('title', 'Community')
  iframe.setAttribute('src', `${APP_URL}/?id=${id}`)

  const handleMessage = (event) => {
    if (iframe.contentWindow !== event.source) return
    let { data } = event
    try {
      data = window.JSON.parse(data)
    } catch (e) {
      // may already be parsed
    }
    switch (data.action) {
      case 'close': {
        document.body.removeChild(iframe)
        window.removeEventListener('message', handleMessage, false)
        const date = new Date()
        date.setDate(date.getDate() + 180) // ~6 months
        document.cookie = `${cookieName}=true; expires=${date.toUTCString()}; path=/`
        break
      }
      case 'ready':
        iframe.contentWindow?.postMessage(
          window.JSON.stringify({
            action: 'setConfig',
            payload: Object.assign({ id }, config),
          }),
          '*',
        )
        break
      case 'resize': {
        const embedContainer = document.getElementById(embedElementId)
        if (embedContainer) {
          embedContainer.style.height = data.height
        }
        break
      }
      case 'submit':
        window.dispatchEvent(new Event('community.websiteSignup'))
        break
      default:
    }
  }

  window.addEventListener('message', handleMessage, false)

  iframe.addEventListener('load', () => {
    iframe.contentWindow?.postMessage({ action: 'initChannel' }, '*', [channel.port2])
  })

  return iframe
}

function checkRule(rule) {
  switch (rule.match_type) {
    case 'contains':
      return window.location.href.indexOf(rule.path) !== -1
    case 'exact':
      return window.location.pathname === rule.path
    default:
      throw new Error(`rule match_type \`${rule.match_type}\` is not supported.`)
  }
}

function loadPopup(config) {
  const isDisabled = document.cookie.indexOf(`${cookieName}=true`) !== -1
  if (isDisabled) {
    return
  }
  if (config.path_rules && config.path_rules.length !== 0) {
    const included = config.path_rules.filter((rule) => rule.select_type === 'included')
    const excluded = config.path_rules.filter((rule) => rule.select_type === 'excluded')
    if (
      // If there are included and none of that paths match
      (included.length !== 0 && !included.some(checkRule)) ||
      // If there are excluded and at least one of the paths match
      (excluded.length !== 0 && excluded.some(checkRule))
    ) {
      return
    }
  }
  setTimeout(() => {
    const iframe = createIframe(config)
    iframe.setAttribute(
      'style',
      'width: 100%; height: 100%; position: fixed; z-index: 2147483647; top: 0; left: 0; border: 0',
    )
    document.body.appendChild(iframe)
  }, config.launch_delay_in_seconds * 1000)
}

function loadEmbed(config) {
  const embedContainer = document.getElementById(embedElementId)
  const iframe = createIframe(config)
  iframe.setAttribute('style', 'width: 100%; height: 100%; border: 0')
  if (embedContainer) {
    embedContainer.appendChild(iframe)
  }
}

function load() {
  if (!id) {
    return
  }
  window.dispatchEvent(new Event('community.embedLoading'))
  fetchConfig(id)
    .then((config) => {
      if (config.display_type === 'inline') {
        loadEmbed(config)
      } else {
        loadPopup(config)
      }
      window.dispatchEvent(new Event('community.embedLoaded'))
    })
    .catch((e) => {
      const evt = new Event('community.embedError') as ErrorEvent
      evt.error = e
      window.dispatchEvent(evt)
    })
}

if (document.readyState === 'complete') {
  load()
} else {
  document.onreadystatechange = function () {
    if (document.readyState === 'complete') {
      load()
    }
  }
}
